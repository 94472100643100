export const inputs = [
    {
        name :'Emri...',
        cols : 'col-span-12 md:col-span-5',
        id : ''
    },{
        name :'Email...',
        cols : 'col-span-12 md:col-span-5',
        id : ''
    },{
        name :'Mesazhi...',
        cols : 'col-span-12 md:col-span-10',
        id : 'message'
    },
]
