import React, { useState, useEffect } from 'react';
import './Marketing.scss';

function Index() {
    const [headingOpacity, setHeadingOpacity] = useState(0)
    const [subheadingOpacity, setSubheadingOpacty] = useState(0)
    const [contentOpacity, setContentOpacty] = useState(0)
    const [firstListOpacity, setfirstListOpacity] = useState(0)
    const [secondListOpacity, setsecondListOpacity] = useState(0)

    useEffect(() => {


        const headingTimeout = setTimeout(() => {
            setHeadingOpacity(1);
        }, 200);

        const subhadingTimeout = setTimeout(() => {
            setSubheadingOpacty(1);
        }, 500); // Adjust timing as needed

        const contentTimeout = setTimeout(() => {
            setContentOpacty(1);
        }, 800); // Adjust timing as needed

        const firstListTimeOut = setTimeout(() => {
            setfirstListOpacity(1);
        }, 1100); // Adjust timing as needed

        const secondListTimeOut = setTimeout(() => {
            setsecondListOpacity(1);
        }, 1300); // Adjust timing as needed
        return () => {
            clearTimeout(headingTimeout);
            clearTimeout(subhadingTimeout);
            clearTimeout(contentTimeout);
            clearTimeout(firstListTimeOut);
            clearTimeout(secondListOpacity);
        };

    }, []);

    return (
        <div className='flex w-full center pt-20'>
            <div className="flex flex-col w-full px-[10vw]">

                <h1 className={`text-[6vw] md:text-[4vw] marketing-heading`} style={{ opacity: headingOpacity, transition: 'opacity 0.5s ease' }}>
                    Çfarë <b className='color-orange'>marketingu </b>bojm na?
                </h1>
                <br />
                <h2 className={`text-[4vw] md:text-[2vw] lg:text-[1.7vw] marketing-subheading `} style={{ opacity: subheadingOpacity, transition: 'opacity 0.5s ease' }}>
                    <b>Edhe nëse e ki produktin ma të mirë në botë, nuk të vyn
                        sen nëse se din njerzt që ekziston</b> - po mos u shqetëso se të ndihmojmë na
                </h2>
                <br />
                <h2 className={`text-[3.8vw] md:text-[2vw] lg:text-[1.7vw] marketing-content `} style={{ opacity: contentOpacity, transition: 'opacity 0.5s ease' }}>
                    Na të ndihmojmë duke e kriju një <b>markë</b> ideale për produktin ose
                    biznesin tuaj, e në vazhdim i krijojmë marketing i cili të bjen audience masive.
                    Në vazhdim mundësh me e pa se çfarë shërbime konkretisht ofrojmë për krijimin e markave dhe markerting
                </h2>
                <br />
                <div className="grid w-full grid-cols-12 md:gap-10">
                    <div className={`col-span-12 md:col-span-6 p-[20px] rounded-[10px]`} style={{ opacity: firstListOpacity, transition: 'opacity 0.5s ease' }}>
                        <ul className='text-[5vw] md:text-[2vw] list-square'>
                            <li className='bold'>Markat</li>
                            <ol className='list-decimal ml-[40px]'>
                                <li className='text-[4vw] md:text-[1.5vw]'>Logo</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Identiteti i markes</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Dizajnimi i webfaqes</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Udhezues i stilit editorial dhe vizual</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Libri i markes</li>
                            </ol>
                        </ul>
                    </div>
                    <div className={`col-span-12 md:col-span-6 p-[20px] rounded-[10px]`} style={{ opacity: secondListOpacity, transition: 'opacity 0.5s ease' }}>
                        <ul className='text-[5vw] md:text-[2vw] list-square w-full'>
                            <li className='bold'>Marketing</li>
                            <ol className='list-decimal ml-[40px] w-full'>
                                <li className='text-[4vw] md:text-[1.5vw] w-full'>Marketing Digjital</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Inbound Marketing</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Neuro Marketing</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Video Marketing</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Marketing ne rrjete sociale</li>
                            </ol>
                        </ul>
                    </div>
                </div>
                <br />
                    <hr />
                <br /> <br />
                <h1 className={`text-[6vw] lg:text-[4vw]`}>Pse të duhen kto?</h1>
                <p className='text-[3.8vw] md:text-[2vw] lg:text-[1.7vw]'>
                <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Diferencimi nga konkurrentët</b>: Marketingu ju lejon të bëni të ditur për avantazhet dhe vlerat unike të produktit ose shërbimit tuaj në krahasim me konkurrentët.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Rritja e shitjeve</b>: Një strategji e mirë e marketingut rrit aftësinë tuaj për të arritur klientë të rinj dhe për të rritur shitjet.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw]] color-pink'>Krijimi i marrëdhënieve afatgjata me klientët</b>: Marketingu nuk është vetëm për të fituar klientë të rinj, por edhe për të mbajtur ata që tashmë i keni. Një strategji e mirë e marketingut ju ndihmon të krijoni marrëdhënie afatgjata me klientët.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Përmirësimi i pozicionimit në treg</b>: Duke përdorur marketingun si një mjet për të identifikuar dhe për të përmbushur nevojat e klientëve, ju përmirësoni pozicionimin tuaj në treg.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Inovacioni dhe zhvillimi i produkteve</b>: Feedback-u që merrni përmes aktiviteteve të marketingut mund të ju ndihmojë të përmirësoni ose të zhvilloni produkte ose shërbime më të mira.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Krijimi i reputacionit</b>: Marketingu ndihmon në ndërtimin dhe ruajtjen e reputacionit të markës suaj në mendjet e konsumatorëve.
                    <br />
                    <br />

                    Në esencë, marketingu është një investim në të ardhmen e biznesit tuaj duke e bërë atë të duket dhe të funksionojë më mirë në një treg të ngarkuar me konkurrencë.
                </p>
            </div>
        </div>
    );
}

export default Index;
