import React, { useEffect, useRef, useState } from 'react';
import './Home.scss';
import { services } from '../../data/servcies';
function About() {
    const aboutRef = useRef(null);
    const contentRef = useRef(null);
    const imageRef = useRef(null);
    const [isScrolledToAbout, setIsScrolledToAbout] = useState(false);
    const [isScrolledToContent, setIsScrolledToContent] = useState(false);
    const [isScrolledToImage, setIsScrolledToImage] = useState(false);
    const [textShadow, setTextShadow] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            if (aboutRef.current && !isScrolledToAbout) {
                const rect = aboutRef.current.getBoundingClientRect();
                const isAboutVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
                setIsScrolledToAbout(isAboutVisible);
            }
            if (contentRef.current && !isScrolledToContent) {
                const rect = contentRef.current.getBoundingClientRect();
                const isContentVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
                setIsScrolledToContent(isContentVisible);
            }
            if (imageRef.current && !isScrolledToImage) {
                const rect = imageRef.current.getBoundingClientRect();
                const isImageVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
                setIsScrolledToImage(isImageVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolledToAbout, isScrolledToContent, isScrolledToImage]);

    const handleMouseMove = (e) => {
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - boundingRect.left;
        const offsetY = e.clientY - boundingRect.top;
    
        // Adjust multiplier values to control the intensity and responsiveness of the effect
        const intensity = 0.1; // Adjust this value to control the intensity of the effect
    
        // Calculate shadow offsets based on mouse position
        const shadowX = (offsetX - boundingRect.width / 2) * intensity;
        const shadowY = (offsetY - boundingRect.height / 2) * intensity;
    
        const shadow = `${shadowX}px ${shadowY}px rgba(227, 6, 19, 0.8),
                        ${shadowX}px ${shadowY}px rgba(255, 237, 0, 1),
                        ${shadowY}px ${shadowX}px rgba(0, 159, 227, 0.7)`;
    
        setTextShadow(shadow);
      };
    
      const handleMouseLeave = () => {
        setTextShadow(''); // Clear text shadow when mouse leaves the element
      };
    return (
        <div className='w-full'>
            <div className="w-full relative z-30 center flex flex-col  ">
                <div className="w-full w-[90vw] ] lg:w-[70vw] flex flex-col md:center px-[5vw] md:px-[15vw] lg:px-[0vw]">
                    <h1 className={`text-[13vw] md:text-[10vw] lg:text-[8vw] ${isScrolledToAbout ? 'about-title-transition' : 'about-title'}`}>Çka jemi na?</h1>
                    <p className={`text-[5vw] mt-[20px] md:text-[3w] lg:text-[3vw] ${isScrolledToAbout ? 'about-subtitle-transition' : 'about-subtitle'}`}>Na ja u shtim <b className='color-orange '>flakën</b> problemeve tua</p>
                </div>
                <div className={` lg:w-[70vw] flex flex-col md:center px-[5vw] md:px-[15vw] lg:px-[0vw]`} ref={aboutRef}>
                    <p className={`mt-[30px] md:mt-20 text-[6vw] md:text-[3.5vw] about ${isScrolledToContent ? 'about-content-transition' : 'about-content'}`} ref={contentRef}>
                        <b>merrflakë</b> është një kompani digjitale e cila oforon shërbime rreth
                        botës së teknologjisë, dhe jemi të specializuar në fushën e <b className='color-orange'>3D</b>.
                        <br /><br />
                        preke njonën nëse don me dit ma shumë
                    </p>
                    <div className="w-full mt-20 grid grid-cols-12">
                        {services.map((e,i) => (
                            <div key={i} className="col-span-4 flex center bold">
                                <a
                                className='text-[4vw] md:text-[4vw] color-blue'
                                href={e}
                                style={{ textShadow: textShadow }}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}>{e}</a>
                            </div>
                        ))}
                    </div>
                </div>
                <img
                    ref={imageRef}
                    className={`w-[100%] mt-10 bg-home `}
                    src="./images/backgrounds/main-bg.png"
                    alt=""
                />
            </div>
        </div>
    );
}

export default About;
