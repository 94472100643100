import React from 'react'
import About from './About';
import Why from './Why'
import Portfolio from './Portfolio';
import Header from './Header';
import './Home.scss'
function Home() {

  return (
    <div className='flex flex-col center relative'>
      <Header/>
      <About />
      <Why />
      <Portfolio />
    </div>
  )
}

export default Home
