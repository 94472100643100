import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Controller from '../../Components/3D/Controller'

function Index() {
  const models = [
    <Controller key='Controller' />,
  ];

  return (
    <div className="w-full flex flex-col center md:px-[10vw] pt-20">
      <div className="w-full grid grid-cols-12">
        {models.map((model, index) => (
          <div className="col-span-12" key={index}>
            <Canvas className="" style={{height: '100vh'}} >
              <OrbitControls enableZoom={true} />
              <ambientLight intensity={0.5} />
              <directionalLight position={[-2, 5, 2]} intensity={1} />
              <Suspense fallback={null}>
                {model}
              </Suspense>
            </Canvas>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Index;
