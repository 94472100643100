export const work = [
    {
      image: 'flowdev (1).png',
      title: 'Flowdev Solutions',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },
    {
      image: 'yllka (1).png',
      title: 'Yllka Leti Art',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },
    {
      image: 'miketMock2 (1).png',
      title: 'Miket',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },
    {
      image: 'tieducation (1).png',
      title: 'Tieducation',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },   {
      image: 'artafinal (1).png',
      title: 'Suvenir Arta',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },   {
      image: 'toca (1).png',
      title: 'Toca Liber',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },   {
      image: 'koscare (1).png',
      title: 'KosCare',
      desc: 'Na e kemi bo website po edhe ja menaxhojme rrjetet sociale',
    },
  ];
