import React, { useState, useEffect } from 'react';


function Index() {
    const [headingOpacity, setHeadingOpacity] = useState(0)
    const [subheadingOpacity, setSubheadingOpacty] = useState(0)
    const [contentOpacity, setContentOpacty] = useState(0)
    const [firstListOpacity, setfirstListOpacity] = useState(0)
    const [secondListOpacity, setsecondListOpacity] = useState(0)

    useEffect(() => {


        const headingTimeout = setTimeout(() => {
            setHeadingOpacity(1);
        }, 200);

        const subhadingTimeout = setTimeout(() => {
            setSubheadingOpacty(1);
        }, 500); // Adjust timing as needed

        const contentTimeout = setTimeout(() => {
            setContentOpacty(1);
        }, 800); // Adjust timing as needed

        const firstListTimeOut = setTimeout(() => {
            setfirstListOpacity(1);
        }, 1100); // Adjust timing as needed
        
        const secondListTimeOut = setTimeout(() => {
            setsecondListOpacity(1);
        }, 1300); // Adjust timing as needed
        return () => {
            clearTimeout(headingTimeout);
            clearTimeout(subhadingTimeout);
            clearTimeout(contentTimeout);
            clearTimeout(firstListTimeOut);
            clearTimeout(secondListOpacity);
        };

    }, []);
    return (
        <div className='flex w-full center pt-20'>
            <div className="flex flex-col w-full px-[10vw]">
               
                <br />
                <h1 className='text-[6vw] md:text-[4vw]' style={{ opacity: headingOpacity, transition: 'opacity 0.5s ease' }}>Digjitalizmi</h1>
                <br />
                <h2 className='text-[4vw] md:text-[2vw] lg:text-[1.7vw]' style={{ opacity: subheadingOpacity, transition: 'opacity 0.5s ease' }}>
                    <b>A don me kon prezent ne dy universe, edhe kto fizik po edhe ne ate <b className='color-orange'>digjital</b></b> - 
                    nese po, ke ardh ne vendin e duhun.
                </h2>
                <br />
                <h2 className='text-[3.8vw] md:text-[2vw] lg:text-[1.7vw] bo ' style={{ opacity: contentOpacity, transition: 'opacity 0.5s ease' }}>
                    Na e bojme te mundun qe prezenca jote digjitale me kon shume e diferencume, duke kriju webfaqe, platforma
                    dhe aplikacione me teknologjite ma te reja e ma te zhvillume. Ne vazhdim mundesh me pa gjithcka qe na ofrojme.
                    
                </h2>
                <br />
                <div className="w-full " style={{ opacity: firstListOpacity, transition: 'opacity 0.5s ease' }}>
                    <div className=" p-[20px] rounded-[10px]">
                        <ul className='text-[2vw]  list-square'>
                            <ol className='list-decimal '>
                                <li className='text-[4vw] md:text-[1.5vw]'>Webfaqe 3D</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Webfaqe me animacione</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Webfaqe informuese</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>E-commerce</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Lojera 2d dhe 3D</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Aplikcaione per telefona mobil</li>
                                <li className='text-[4vw] md:text-[1.5vw]'>Platforma</li>
                            </ol>
                        </ul>
                    </div>
                </div>

                <br /> <br />
                <h1 className='text-[6vw] lg:text-[4vw]'>Pse te duhen kto?</h1>
                <p className='text-[3.8vw] md:text-[2vw] lg:text-[1.7vw]'>
                <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Rritja e efikasitetit dhe produktivitetit</b>:  Duke përdorur teknologjitë digjitale për automatizimin e proceseve, një biznes mund të rrisë efikasitetin dhe produktivitetin. Për shembull, sistemet e menaxhimit të informacionit dhe softueret e automatizimit të proceseve lehtësojnë detyrat repetitive dhe mundësojnë qasje më të shpejtë në informacion.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Përmirësimi i ekperiencës së klientit</b>:  Digitalizimi ofron mënyra të reja për të përmbushur nevojat e klientëve. Për shembull, platformat e shitjes online, faqet e internetit të përshtatur për celularë, dhe shërbimet e klientit në internet rrisin qasjen dhe përshtatshmërinë për konsumatorët.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw]] color-pink'>Zhvillimi i Strategjive të marketingut</b>: Teknologjitë digjitale ofrojnë mënyra të avancuara për të identifikuar, përballuar dhe angazhuar audiencën. Për shembull, përdorimi i mediave sociale për reklamim dhe analizat e të dhënave për të kuptuar preferencat e klientëve ndihmojnë në hartimin e strategjive më të suksesshme të marketingut.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Reduktimi i kostove</b>:  Digitalizimi redukton kostot operative duke lehtësuar shkëmbimin e informacionit, duke përmirësuar menaxhimin e stokut dhe duke lehtësuar proceset e shitjes dhe dërgesës. Një biznes i digitalizuar ka mundësi të jetë më konkurrues në treg dhe të japë ofertat më të mira për klientët.
                    <br />
                    <br />
                    <b className='text-[4vw] md:text-[2.2vw] lg:text-[2vw] color-pink'>Siguria e të dhënave dhe nformacionit</b>: Duke përdorur teknologjitë e sigurisë digjitale, si enkriptimi i të dhënave dhe sistemet e sigurisë së informacionit, një biznes mund të mbrojë të dhënat e klientëve dhe të minimizojë rreziqet e shkeljeve të sigurisë.
                    
                    Këto arsye tregojnë se digjitalizimi është jo vetëm i rëndësishëm, por edhe i dobishëm për zhvillimin dhe suksesin e një biznesi në kohën tonë të digitalizuar.
                </p>
            </div>
        </div>
    );
}

export default Index;
