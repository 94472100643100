import React from 'react'

function Footer() {
    const contact = [
        {
          'icon': 'phone-call',
          'text': '+38349267217'
        },
        {
          'icon': 'email',
          'text': 'merrflake@gmail.com'
        },
        {
          'icon': 'instagram',
          'text': '@merrflake'
        },
        {
          'icon': 'facebook',
          'text': '@merrflake'
        },
        {
          'icon': 'linkedin',
          'text': '@merrflake'
        },
        {
          'icon': 'tik-tok',
          'text': '@merrflake'
        },
      ]
  return (
    <div className='w-full flex flex-col z-80 mt-40 opacity-100'>
       <svg className=' relative top-[5px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0E1428" fill-opacity="1" d="M0,288L14.1,277.3C28.2,267,56,245,85,224C112.9,203,141,181,169,186.7C197.6,192,226,224,254,250.7C282.4,277,311,299,339,272C367.1,245,395,171,424,160C451.8,149,480,203,508,218.7C536.5,235,565,213,593,170.7C621.2,128,649,64,678,85.3C705.9,107,734,213,762,261.3C790.6,309,819,299,847,298.7C875.3,299,904,309,932,309.3C960,309,988,299,1016,256C1044.7,213,1073,139,1101,138.7C1129.4,139,1158,213,1186,256C1214.1,299,1242,309,1271,314.7C1298.8,320,1327,320,1355,309.3C1383.5,299,1412,277,1426,266.7L1440,256L1440,320L1425.9,320C1411.8,320,1384,320,1355,320C1327.1,320,1299,320,1271,320C1242.4,320,1214,320,1186,320C1157.6,320,1129,320,1101,320C1072.9,320,1045,320,1016,320C988.2,320,960,320,932,320C903.5,320,875,320,847,320C818.8,320,791,320,762,320C734.1,320,706,320,678,320C649.4,320,621,320,593,320C564.7,320,536,320,508,320C480,320,452,320,424,320C395.3,320,367,320,339,320C310.6,320,282,320,254,320C225.9,320,198,320,169,320C141.2,320,113,320,85,320C56.5,320,28,320,14,320L0,320Z"></path></svg>
       <div className="bg-color-blue z-40 opacity-100 w-full center py-[15vh] flex flex-col ">
              <div className="flex center w-full">
              <img className='w-[5vw]' src="/images/logos/logoT.png" alt="" />
            <h1 className='text-[5vw] ml-[10px] text-white'>merr<b className=''>flakë</b></h1>
              </div>
            <div className="flex flex-wrap flex-col md:flex-row center px-[5vw]">
          {contact.map((data, i) => (
            <div key={i} className="flex center mr-[30px] mt-[20px] center w-full">
              <img src={`/images/socials/${data.icon}.png`} alt="" className="w-[20px] h-[20px]" />
              <p className="ml-[10px] text-white text-[16px]">{data.text}</p>
            </div>
          ))}
        </div>
       </div>
    </div>
  )
}

export default Footer
