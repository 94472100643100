import React from 'react'
import Navigation  from './Navigation'
import Footer from './Footer'
function Index(props) {
  return (
    <div className='z-40'> 
        <Navigation/>
        {props.children}
        <Footer/>
    </div>
  )
}

export default Index
