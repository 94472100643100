import React from 'react'
import './Education.scss'
function Cards(props) {
  return (
    <div className='w-full flex flex-col cards-container bg-white'>
      <img src={`/images/cards/${props.image}`} alt="" />
      <div className="inner-cards-container">
        <h1 className='text-[25px]'>Moduli <b className='color-orange'>{props.title}</b></h1>
        <br />
        <p className='text-[16px]'>{props.text}</p>
        <br />
        <br />
        <a className='py-[10px] px-[20px] bg-color-blue text-white text-center' href={props.link}>Regjistrohu tani</a>
      </div>

    </div>
  )
}

export default Cards
