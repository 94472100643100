import React, { useState, useEffect } from 'react'
import Cards from './Cards'
import { modules } from '../../../data/modules'
import { modules_yearly } from '../../../data/modules_yearly'
function Index() {
  

  const [headingOpacity, setHeadingOpacity] = useState(0)
  const [subheadingOpacity, setSubheadingOpacty] = useState(0)
  const [contentOpacity, setContentOpacty] = useState(0)
  const [maincardOpacity, setmaincardOpacity] = useState(0)

  useEffect(() => {


    const headingTimeout = setTimeout(() => {
      setHeadingOpacity(1);
    }, 200);

    const subhadingTimeout = setTimeout(() => {
      setSubheadingOpacty(1);
    }, 500); // Adjust timing as needed

    const contentTimeout = setTimeout(() => {
      setContentOpacty(1);
    }, 800); // Adjust timing as needed

    const firstListTimeOut = setTimeout(() => {
      setmaincardOpacity(1);
    }, 1100); // Adjust timing as needed


    return () => {
      clearTimeout(headingTimeout);
      clearTimeout(subhadingTimeout);
      clearTimeout(firstListTimeOut);
      clearTimeout(contentTimeout);
    };

  }, []);
  return (
    <div className='w-full flex flex-col center  second-edu-container'>
      <div className="felx center bg-white w-full pt-40">
        <div className="w-full center px-[10vw] ">
          <h1 className='text-[8vw] md:text-[2.5vw]' style={{ opacity: headingOpacity, transition: 'opacity 0.5s ease' }}>Edukohu per <b className='color-pink'>teknologjitë </b>
            më të avancuara</h1>
          <br />
          <hr />
          <br />
          <h2 className='text-[15px] md:text-[23px] plan'style={{ opacity: subheadingOpacity, transition: 'opacity 0.5s ease' }}>planprograme vjetore/ intenzive / sezonale </h2>
          <br />
          <p className='text-[20px] md:text-[25px]' style={{ opacity: contentOpacity, transition: 'opacity 0.5s ease' }}>
            <b>merr<b className='color-orange' >flakë</b></b> ka per qellim te edukoj
            personat rreth perdorimit te teknologjive me te avnacuara dhe integrimin e tyre
            ne projekte reale
          </p>
          <br />
          <div className="w-full p-[20px] md:p-[40px] summer-camp-container mt-10" style={{ opacity: maincardOpacity, transition: 'opacity 0.5s ease' }}>
            <h1 className='text-[8vw] md:text-[2vw] bg-color-pink text-white w-fit px-[10px]'>Kampi Veror</h1>
            <p className='text-[18px] mt-10 text-white'>
              Perjeto nje eksperience te paharrueshme ne kampin veror te <b>merr</b><b className='color-orange'>flake</b>. Meso te gjithe aspektet e teknologjise
              dhe futi ato ne praktike. Kampi mbahet 2 her, ne korrik dhe gusht. Mbahet
              4 here ne jave dhe 4 or ne dite. Kampi perfshine keto fusha:

            </p>
            <ul className='list-disc mt-10 text-white text-[20px] pl-[20px]'>
              <li>Programim 3D <b className="color-orange">(Three.js)</b></li>
              <li>FrontEnd <b className="color-orange">(React.js)</b></li>
              <li>BackEnd <b className="color-orange">(Node.js)</b></li>
              <li>Cyber Security</li>
              <li>Dizajn Grafik</li>
            </ul>
            <button className="mt-10">
              <a className='bg-color-pink py-[10px] px-[20px] text-white'>Regjistrohu tani</a>

            </button>
          </div>
        </div>
      </div>
      <div className="w-full">
        <svg className='svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="white" fill-opacity="1" d="M0,32L14.1,58.7C28.2,85,56,139,85,160C112.9,181,141,171,169,138.7C197.6,107,226,53,254,58.7C282.4,64,311,128,339,176C367.1,224,395,256,424,240C451.8,224,480,160,508,154.7C536.5,149,565,203,593,213.3C621.2,224,649,192,678,192C705.9,192,734,224,762,240C790.6,256,819,256,847,261.3C875.3,267,904,277,932,240C960,203,988,117,1016,85.3C1044.7,53,1073,75,1101,112C1129.4,149,1158,203,1186,186.7C1214.1,171,1242,85,1271,53.3C1298.8,21,1327,43,1355,48C1383.5,53,1412,43,1426,37.3L1440,32L1440,320L1425.9,320C1411.8,320,1384,320,1355,320C1327.1,320,1299,320,1271,320C1242.4,320,1214,320,1186,320C1157.6,320,1129,320,1101,320C1072.9,320,1045,320,1016,320C988.2,320,960,320,932,320C903.5,320,875,320,847,320C818.8,320,791,320,762,320C734.1,320,706,320,678,320C649.4,320,621,320,593,320C564.7,320,536,320,508,320C480,320,452,320,424,320C395.3,320,367,320,339,320C310.6,320,282,320,254,320C225.9,320,198,320,169,320C141.2,320,113,320,85,320C56.5,320,28,320,14,320L0,320Z"></path></svg>

        <div className="w-full= px-[10vw] first-edu-container mt-30" >
          <h2 className='text-[30px] text-white w-full mt-20 '>Modulet intenzive</h2>
          <br />
          <div className="w-full grid grid-cols-12 md:gap-10">

            {modules.map((data, i) => (
              <div key={i} className="col-span-12 mt-5 md:mt-0 md:col-span-4">
                <Cards image={data.image} text={data.text} title={data.title} />
              </div>
            ))}
          </div>
        </div>
        <svg className='relative top-[3px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,288L14.1,277.3C28.2,267,56,245,85,224C112.9,203,141,181,169,186.7C197.6,192,226,224,254,250.7C282.4,277,311,299,339,272C367.1,245,395,171,424,160C451.8,149,480,203,508,218.7C536.5,235,565,213,593,170.7C621.2,128,649,64,678,85.3C705.9,107,734,213,762,261.3C790.6,309,819,299,847,298.7C875.3,299,904,309,932,309.3C960,309,988,299,1016,256C1044.7,213,1073,139,1101,138.7C1129.4,139,1158,213,1186,256C1214.1,299,1242,309,1271,314.7C1298.8,320,1327,320,1355,309.3C1383.5,299,1412,277,1426,266.7L1440,256L1440,320L1425.9,320C1411.8,320,1384,320,1355,320C1327.1,320,1299,320,1271,320C1242.4,320,1214,320,1186,320C1157.6,320,1129,320,1101,320C1072.9,320,1045,320,1016,320C988.2,320,960,320,932,320C903.5,320,875,320,847,320C818.8,320,791,320,762,320C734.1,320,706,320,678,320C649.4,320,621,320,593,320C564.7,320,536,320,508,320C480,320,452,320,424,320C395.3,320,367,320,339,320C310.6,320,282,320,254,320C225.9,320,198,320,169,320C141.2,320,113,320,85,320C56.5,320,28,320,14,320L0,320Z"></path></svg>
      </div>
      <div className="w-full relative top-[2px] bg-white">
        <div className="w-full= px-[10vw] mt-20" >
          <h1 className='text-[30px] text-black w-full '>Modulet vjetore</h1>
          <br />
          <div className="w-full grid grid-cols-12 md:gap-10">

            {modules_yearly.map((data, i) => (
              <div key={i} className="col-span-12 md:col-span-4 mt-5 m:mt-0">
                <Cards image={data.image} text={data.text} title={data.title} />
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Index
