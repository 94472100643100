import React, { Suspense, useEffect, useState } from 'react'
import './Services.scss'

function Index() {
const [spansSlow, setSpansSlow] = useState([]);
const [spansFast, setSpansFast] = useState([]);
const [width, setWidth] = useState(window.innerWidth);

useEffect(() => {
        const handleMouseMove = (e) => {
                const normalizedPosition = e.pageX / (width / 2) - 1;
                const speedSlow = 100 * normalizedPosition;
                const speedFast = 200 * normalizedPosition;
                spansSlow.forEach((span) => {
                        span.style.transform = `translateX(${speedSlow}px)`;
                });
                spansFast.forEach((span) => {
                        span.style.transform = `translateX(${speedFast}px)`;
                });
        };

        const handleWindowResize = () => {
                setWidth(window.innerWidth);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('resize', handleWindowResize);

        return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('resize', handleWindowResize);
        };
}, [spansSlow, spansFast, width]);

useEffect(() => {
        const slowSpans = document.querySelectorAll('.spanSlow');
        const fastSpans = document.querySelectorAll('.spanFast');
        setSpansSlow(slowSpans);
        setSpansFast(fastSpans);
}, []);
return (
        <div className='md:h-[100vh] pt-10'>

                <div class="wrap mt-40">
                        <div class="line">
                                <div class="a-left">
                                        <div class="content">
                                                <span class="spanSlow bg-color-blue text-white"><a href="/marketing">MARKETING→</a></span>
                                        </div>
                                </div><div class="a-right">
                                        <div class="content ">
                                                <span class="spanSlow bg-color-blue text-white"><a href="/marketing">MARKETING→</a></span>
                                        </div>
                                </div>
                        </div>
                        <div class="line">
                                <div class="a-left">
                                        <div class="content ">
                                                <span class="spanSlow bg-color-orange"><a href="/edukim">EDUKIM→</a></span>
                                        </div>
                                </div><div class="a-right">
                                        <div class="content ">
                                                <span class="spanSlow bg-color-orange"><a href="/edukim">EDUKIM→</a></span>
                                        </div>
                                </div>
                        </div>
                        <div class="line">
                                <div class="a-left">
                                        <div class="content ">
                                                <span class="spanFast bg-color-yellow"><a href="/digjitalizim">DIGJITALIZIM→</a></span>
                                        </div>
                                </div><div class="a-right">
                                        <div class="content ">
                                                <span class="spanFast bg-color-yellow"><a href="/digjitalizim">DIGJITALIZIM→</a></span>
                                        </div>
                                </div>
                        </div>
                </div>
              
        </div>
)
}

export default Index
