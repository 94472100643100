import React, { useState } from 'react'
import './Contact.scss'
import emailjs from '@emailjs/browser';
import { inputs } from '../../data/inputs';
import Input from './Input';
function Index() {
  const contact = [
    {
      'icon': 'phone-call',
      'text': '+38349267217'
    }
  ]

  const transformString = (str) =>
    str
      .toLowerCase()
      .replace(/\s/g, "_")
      .replace(/\.{3}$/g, "");

  const [emri, setEmri] = useState('')
  const [email, setEmail] = useState('')
  const [mesazhi, setMesazhi] = useState('')

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      emri,
      email,
      mesazhi,
    };

    emailjs
      .send(
        "service_0kwgq6f",
        "template_cn9jkqi",
        templateParams, // Pass the template variables as an object
        "D5-5HpHTuzSPoF8xA"
      )
      .then(
        (result) => {
        },
        (error) => {
        }
      );
  };

  return (
    <div className='w-full flex  flex-col pt-[20vh]'>
      <img className='w-[100%] bg-yellow fixed opacity-5' src="./images/background.png" alt="" />
      <form onSubmit={sendEmail} className="w-full" action="">
        <div className='w-full px-[10vw] z-20'>
          <div className="flex flex-wrap">
            {contact.map((data, i) => (
              <div className="flex center mr-[30px] mt-[20px] ">
                <img src={`/images/socials/${data.icon}.png`} alt="" className="w-[20px] h-[20px]" />
                <p className="ml-[10px] text-[16px]">{data.text}</p>
              </div>
            ))}
          </div>
          <h1 className='text-[7vw] md:text-[5vw]'>thirrna, <b className=''>shkruna</b>
            <b className='text-[3vw] md:text-[2vw]'>- qysh të don ti</b></h1>

          <div className="w-full grid grid-cols-12 mt-[40px] md:gap-5">
            {inputs.map((e,i) => (
              <Input
              key={i}
                name={transformString(e.name)}
                onChange={(e) => {
                  const value = e.target.value;
                  if (transformString(e.name) === 'emri') setEmri(value);
                  else if (transformString(e.name) === 'email') setEmail(value);
                  else if (transformString(e.name) === 'mesazhi') setMesazhi(value);
                }}
                defaultValue={
                  transformString(e.name) === 'emri'
                    ? emri
                    : transformString(e.name) === 'email'
                      ? email
                      : mesazhi
                }
                id={e.id}
                cols={e.cols} />
            ))}
          </div>
          <br /><br />
          <button className='meniher bg-black text-white w-fit text-[4vw] md:text-[2vw] lg:text-[1.5vw]' type='submit'>dërgoje</button>

        </div>
      </form>

    </div>
  )
}

export default Index
