export const modules_yearly = [
    {
      "image": 'card-bg-3D-purple.png',
      "title": '3D',
      'text': 'Në këtë modul nxënësit mësohen si te programojne webfaqe 3 dimensaionale duke përdorur Three.js'
    },
    {
      "image": 'card-bg-animacion.png',
      "title": 'Animacion',
      'text': 'Në këtë modul nxënësit mësohen si te krijojne animaciona interaktive ne webfaqe te ndryshme.'
    },
    {
      "image": 'card-bg-frontend-purple.png',
      "title": 'FrontEnd',
      'text': 'Në këtë modul nxënësit mësohen të programojnë pjesën vizuale të një webfaqeje, duke përdorur HTML,CSS,JS dhe React.js '
    },
    {
      "image": 'card-bg-backend-purple.png',
      "title": 'BackEnd',
      'text': 'Në këtë modul nxënësit mësohen të krijojnë pjesën jo-vizuale të webfaqeve/applikacioneve duke përdorur Nodejs'
    },
    {
      "image": 'card-bg-fullstack-purple.png',
      "title": 'FullStack',
      'text': 'Në këtë modul nxënësit mësohen si të krijojnë pjesën vizuale por edhe atë jo-vizuale të nje webfaqeje, duke përdorur React.js, Node.js dhe Next.js'
    },
    {
      "image": 'card-bg-gamedev-purple.png',
      "title": 'GameDev',
      'text': 'Në këtë modul nxënësit mësohen si te programojne webfaqe 3 dimensaionale, dhe si te krijojne/dizajnojne objekte 3D'
    }
  ]