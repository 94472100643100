import React from 'react'
import './About.scss'
function Index() {
  return (
    <div className='pt-20 w-full flex flex-col start '>
      <div className="w-full px-[10vw]">
        <h1 className='text-[5vw]'>merr<b className='color-orange'>flakë</b>
          <b className='text-[2vw]'>- çka jemi more na?</b></h1>
        <br />
        <p className="desc text-[3.8vw] md:text-[2vw] lg:text-[2vw]">
          Na jemi një kompani digjitale e cila oforon shërbimerreth
          botës së teknologjisë, dhe jemi të specializum në fushën e 3D. Na shkurt e shqip rrisim <b className='color-orange'>klienta </b>
          po edhe <b className='color-orange'>dije</b>.
          <br />
            <br />
            Ma konkretisht na krijojmë marketing për biznese ose individ, e bojmë digjitalizimin 
            e tyne, po njëkohësisht i edukojmë edhe personat e ri për me i përdor gjuhët
            programuse dhe strategjitë inovative të cilat na unifikojnë neve.
            <br />
            <br />
            Çka e bon "merrflakë ma ndryshe prej tjerve, janë tekonologjitë më të avancume
            të cilat i përdorim. Duke pru website unike dhe marketing më dinamik nga ai cka shihni zakonisht, me ane
            të përdorimit të animacioneve dhe integrimin e pjesës 3D."
            <br />
            <br />
            <b>merr<b className='color-orange'>flakë</b> nuk ndjek trende, por i krijon ato</b>
            <br /><br />
          Jemi themelu në vitin 2024 dhe kryesisht operojmë në Kosove, duke u mundu 
          maksimalisht me i ngrit  bizneset vendore me anë të digjitalizimit unik dhe 
            edukimin e lartë të personave të ri në Kosovë.
        </p>
      </div>
      <img className='w-[100%] bg-about fixed opacity-10' src="./bg2.png" alt="" />

    </div>
  )
}

export default Index
