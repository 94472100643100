import React from 'react'

function Input(props) {
  return (
    <div className={`${props.cols} `}>
          <input id={props.id} className='w-full contact-inputs' placeholder={props.name}></input>
    </div>
  )
}

export default Input
