import React, { useState, useEffect } from 'react';
import './Portfolio.scss';
import { work } from '../../data/work';
import { logos } from '../../data/logos';
function Index() {
  const [imagesLoaded, setImagesLoaded] = useState(false);



  useEffect(() => {
    const loadImage = (img) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(img);
        image.onerror = (err) => reject(err);
        image.src = `/images/mockups/${img}`;
      });
    };

    Promise.all(work.map((item) => loadImage(item.image)))
      .then(() => setImagesLoaded(true))
      .catch((err) => console.error('Image loading error:', err));
  }, []);

  return (
    <div>
      {!imagesLoaded && (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className={`w-full flex flex-col center ${imagesLoaded ? 'visible' : 'hidden'}`}>

        <div className="w-full px-[10vw] mt-20">
          <div className="w-full grid mt-[10vh] grid-cols-12 gap-5">
            {work.map((data, i) => (
              <div key={i} className="col-span-12 md:col-span-6 flex flex-col main-cont relative">
                <h2 className="text-[20px] text-right">{data.title}</h2>
                <hr className="mt-[5px]" />
                <img
                  src={`/images/mockups/${data.image}`}
                  className="mock-image"
                  alt=""
                  style={{ display: imagesLoaded ? 'block' : 'none' }}
                />
              </div>
            ))}
          </div>
          <br />
          <hr />
          <br />
          <img src="/images/mockups/artalogo.jpg" alt="" />
          <img className='mt-10' src="/images/mockups/artatraditionl.png" alt="" />
          <img className='mt-10' src="/images/mockups/miketB.png" alt="" />
          <img className='mt-10' src="/images/mockups/tieducationMock.png" alt="" />
        </div>
      </div>
    </div>
  );
}
export default Index;
