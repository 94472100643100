import React, { Suspense, useEffect, useRef, useState } from 'react'


function Portfolio() {
    const imageRef  = useRef(null)
    const [isScrolledToImage, setIsScrolledToImage] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            
            if (imageRef.current && !isScrolledToImage) {
                const rect = imageRef.current.getBoundingClientRect();
                const isImageVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
                setIsScrolledToImage(isImageVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolledToImage]);
    return (
        <div className='w-full flex flex-col center z-20  mt-20'  ref={imageRef}>
            <img  src="/images/mockups/yllka (1).png" className={`z-20 w-[90%] md:w-[60%] ${isScrolledToImage ? 'index-image-transition' : 'index-image'}`} alt="" />
            <div className="flex center mt-10 z-20">
                <button className='meniher bg-black  text-white'><a href="/shkënditë">kqyri edhe tjerat</a></button>
            </div>
        </div>
    )
}

export default Portfolio
