import React, { useEffect,  useState } from 'react'

function Header() {
    const [spansSlow, setSpansSlow] = useState([]);
    const [spansFast, setSpansFast] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [meniherOpacity, setMeniherOpacity] = useState(0);
    const [otherButtonOpacity, setOtherButtonOpacity] = useState(0);
    const [flameOpacity, setFlameOpacity] = useState(0)

    useEffect(() => {
        const slowSpans = document.querySelectorAll('.spanSlow');
        const fastSpans = document.querySelectorAll('.spanFast');
        setSpansSlow(slowSpans);
        setSpansFast(fastSpans);
    
        const meniherTimeout = setTimeout(() => {
          setMeniherOpacity(1);
        }, 1400);
    
        const otherButtonTimeout = setTimeout(() => {
          setOtherButtonOpacity(1);
        }, 1600); // Adjust timing as needed
    
        const flameTimeout = setTimeout(() => {
          setFlameOpacity(0.5);
        }, 1900); // Adjust timing as needed
        return () => {
          clearTimeout(meniherTimeout);
          clearTimeout(otherButtonTimeout);
          clearTimeout(flameTimeout);
        };
    
      }, []);
    
    
  return (
    <div className='w-full relative header justify-center flex  flex-col h-[100vh]'>
        <div className="flex flex-col md:flex-row w-full center">
          <img className='w-[70%] md:w-[45%] title-image opacity-100 z-20 ' src="./images/backgrounds/titlebg.png" alt="" />
          <h1 className=" text-[35vw] md:text-[13vw] header-title z-20">flakë</h1></div>
        <div className="mt-20 md:mt-0 w-full flex center w-full z-20">
          <button className='meniher bg-black text-white  text-[4vw] md:text-[2vw] lg:text-[1.5vw]' style={{ opacity: meniherOpacity, transition: 'opacity 0.5s ease' }}><a href="/thirrna"
          >meniher</a></button>
          <button
            className='vone text-[4vw] md:text-[2vw] lg:text-[1.5vw]'
            style={{ opacity: otherButtonOpacity, transition: 'opacity 0.5s ease' }}
          >
            <a href="/shkënditë ">pak ma vonë</a>
          </button>
        </div>
        <div className="fixed w-full flex center">
          <img style={{ opacity: flameOpacity, transition: 'opacity 1s ease' }} src='/images/animations/Flame_GIF_1.gif' className='opacity-30 w-[45%] ' />
        </div>
      </div>
  )
}

export default Header
