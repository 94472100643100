import React, { useState, useEffect } from 'react';
import './Layout.scss';

function Navigation() {
    const list = [
        'shërbimet',
        'shkënditë',
        'sille',
        'thirrna',
        'na'
    ];

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 728);
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    useEffect(() => {
        handleResize(); // Initial check on component mount

        // Add event listener for resize events
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount

    return (
        <div className='w-full flex z-50 items-center bg-white py-5 px-[5vw] fixed'>
            <div className="z-50 grid grid-cols-12 w-full">
                {/* Brand */}
                <div className="brand col-span-6 flex items-center ">
                    <img width={'30px'} src="/images/logos/logoT.png" alt="" />
                    <h1 className='brand_title ml-[10px] '><a href="/">merrflakë</a></h1>
                </div>
                {/* Navigation button */}
                <div className="col-span-6 flex justify-end items-center">
                    {isSmallScreen ? (
                        <button onClick={toggleNav}
                            className="button-container flex h-fit p-[8px] flex-col justify-center items-end round-[10px]">
                            <div
                                style={{
                                    transform: `${isNavOpen ? 'rotate(40deg)' : ''}`
                                }}
                                className="nav-lines w-[4vw]"></div>
                            <div
                                style={{
                                    transform: `${isNavOpen ? 'rotate(-40deg)' : ''}`,
                                    top: `${isNavOpen ? '-5px' : '0px'}`
                                }}
                                className="nav-lines relative w-[4vw] mt-[4px]"></div>
                            <div
                                style={{
                                    opacity: `${isNavOpen ? '0' : '1'}`,
                                }}
                                className="nav-lines w-[4vw] mt-[4px]" id='bottom-line'></div>
                        </button>
                    ) : (
                        <ul className='flex w-full justify-end items-center'>
                            {list.map((item) => (
                                <li  className='mx-[20px] text-[20px]' key={item}>
                                    <a href={`/${item}`}>{item}</a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            {/* Navigation links */}

            <div
                style={{
                    transform: `translateX(${isNavOpen ? '0vw' : '-100vw'})`
                }}
                className="fixed top-[10vh] navigation-responsive left-0 bg-white w-[100vw] h-screen flex flex-col items-center z-30 pt-10">
                <ul className='flex flex-col'>
                    {list.map((item) => (
                        <li className='my-[20px] text-[40px]' key={item}>
                            <a href={`/${item}`}>{item}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Navigation;
