import React, { useEffect, useRef, useState } from 'react';
import './Home.scss';

function Why() {
  const whyRef = useRef(null);
  const whyRef2 = useRef(null);
  const [isScrolledToWhy, setIsScrolledToWhy] = useState(false);
  const [isScrolledToWhyText, setIsScrolledToWhyText] = useState(false);
  const [transitionDone, setTransitionDone] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!transitionDone && whyRef.current && whyRef2.current) {
        const rect1 = whyRef.current.getBoundingClientRect();
        const rect2 = whyRef2.current.getBoundingClientRect();
        const isAboutVisible = rect1.top <= window.innerHeight && rect1.bottom >= 0;
        const isAboutTextVisible = rect2.top <= window.innerHeight && rect2.bottom >= 0;
        
        if (isAboutVisible) {
          setIsScrolledToWhy(true);
        }
        
        if (isAboutTextVisible) {
          setIsScrolledToWhyText(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [transitionDone]);

  useEffect(() => {
    if (isScrolledToWhy && isScrolledToWhyText) {
      setTransitionDone(true);
    }
  }, [isScrolledToWhy, isScrolledToWhyText]);

  return (
    <div className='w-full grid grid-cols-12 md:mt-0 mt-20 px-[5vw]'>
      <div className={`md:col-span-6 w-[60vw] md:ml-[0px] md:w-[100%] ${isScrolledToWhy ? 'why-transition' : 'why-before'}`}>
        <div className="w-full" ref={whyRef}>
          <ul className="Words">
            <li className="Words-line">
              <p className="threeD">&nbsp;</p>
              <p className="threeD">A E DIN PSE</p>
            </li>
            <li className="Words-line">
              <p className="threeD">A E DIN PSE</p>
              <p className="threeD">NA JEMI</p>
            </li>
            <li className="Words-line">
              <p className="threeD">NA JEMI</p>
              <p className="threeD" id="color-pink">
                UNIK
              </p>
            </li>
            <li className="Words-line">
              <p className="threeD color-orange" id="color-pink">
                UNIK
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div ref={whyRef2} className={`col-span-12 lg:col-span-6 flex flex-col center why-text z-20 ${isScrolledToWhyText ? 'why-transition' : 'why-before'}`}>
        <p className="text-[5vw] w-[100%] md:w-[100%] md:text-[3vw] lg:text-[2vw]">
          Neve na bën unik <b>dinamika</b>, <b>animacionet</b> po edhe vizioni <b>3D</b>. Njejt si emri na realisht
          bëjmë që ju me marr flakë, duke kriju marketing të mbushun me jetë e strategji, webfaqe në
          këndvështrime reale (3d) dhe me animacione që përqojnë ndjesi pozitive për vizituesit e juaj.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}

export default Why;
