import React from 'react'

function NotFound() {
    return (
        <div className='flex flex-col w-full center h-[100vh]'>
            <div className="flex-col flex">
                <h1 className='text-[6vw]'>Çka po lyp nuk <b className="color-orange">ekziston</b></h1>
            </div>
        </div>
    )
}

export default NotFound
