import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Pages/Home/Index'
import Layout from './Layout/Index'
import About from './Pages/About/Index'
import Services from './Pages/Services/Index'
import ThreeD from './Pages/3D/Index'
import NoPage from './NotFound'
import Contact from './Pages/Contact/Index'
import Education from './Pages/Services/Education/Index'
import Digital from './Pages/Services/Digital/Index'
import Marketing from './Pages/Services/Marketing/Index'
import Portfolio from './Pages/Portfolio/Index'

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/sille" element={<ThreeD />} />
          <Route path="/na" element={<About />} />
          <Route path="/shërbimet" element={<Services />} />
          <Route path="/thirrna" element={<Contact />} />
          <Route path="/shkënditë" element={<Portfolio />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/digjitalizim" element={<Digital />} />
          <Route path="/edukim" element={<Education />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
