
import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model(props) {
  const { nodes, materials, animations } = useGLTF('/3d models/controller.gltf')
  const group = useRef()
  const { actions } = useAnimations(animations, group)
  useFrame((state, delta) => {
    group.current.rotation.y += 0.3 * delta;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} >
        <mesh geometry={nodes.Object_2.geometry} material={materials.phong2SG} scale={0.08} />
        <mesh geometry={nodes.Object_3.geometry} material={materials.phong2SG} scale={0.08}/>
      </group>
    </group>
  )
}

useGLTF.preload('/3d models/controller.gltf')
